import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './auth/logged-in.guard';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    children: [
      { path: '', redirectTo: 'sites', pathMatch: 'full' },
      {
        path: 'sites',
        data: {
          title: 'Sites',
          animation: 1,
        },
        loadChildren: () =>
          import('./sites/sites.module').then((m) => m.SitesModule),
      },
    ],
  },
  { path: 'sign-in', component: SignInComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
