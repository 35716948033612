import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import {
  catchError,
  distinctUntilChanged,
  map,
  Observable,
  of,
  ReplaySubject,
  share,
  switchMap,
  tap,
} from 'rxjs';
import {
  CurrentUserDocument,
  CurrentUserQuery,
  LoginDocument,
  LoginMutation,
} from '../core/graphql';
import { TokenService } from '../core/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly isAuthenticated = this.token.token.pipe(
    distinctUntilChanged(),
    map((token) => !!token)
  );

  readonly user = this.isAuthenticated.pipe(
    distinctUntilChanged(),
    switchMap((token) => (token ? this.getCurrentUser() : of(null))),
    share({
      connector: () => new ReplaySubject(0),
      resetOnRefCountZero: true,
      resetOnComplete: true,
      resetOnError: true,
    })
  );

  constructor(
    private token: TokenService,
    private router: Router,
    private apollo: Apollo
  ) {}

  getCurrentUser(): Observable<CurrentUserQuery['me'] | undefined> {
    return this.apollo.query({ query: CurrentUserDocument }).pipe(
      map(({ data }) => data.me),
      catchError(() => of(undefined))
    );
  }

  signIn(
    login: string,
    password: string
  ): Observable<LoginMutation | undefined | null> {
    return this.apollo
      .mutate({ mutation: LoginDocument, variables: { login, password } })
      .pipe(
        map(({ data }) => {
          if (data) {
            this.token.set(data.login.token);
          }
          return data;
        })
      );
  }

  signInAndRemember(
    login: string,
    password: string
  ): Observable<LoginMutation | undefined | null> {
    return this.signIn(login, password).pipe(
      tap((data) => {
        if (data) {
          this.token.persist(data.login.token);
        }
      })
    );
  }

  logout(): void {
    this.token.remove();
    this.apollo.client.resetStore();
    this.router.navigateByUrl('/sign-in');
  }
}
