import { Component, ViewEncapsulation } from '@angular/core';
import { DarkModeService } from '../dark-mode.service';

@Component({
  selector: 'wzc-dark-mode-toggle',
  templateUrl: './dark-mode-toggle.component.html',
  styleUrls: ['./dark-mode-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DarkModeToggleComponent {
  constructor(public darkMode: DarkModeService) {}
}
