import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { GraphQLModule } from './graphql.module';
import { HomeModule } from './home/home.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    HomeModule,
    AuthModule,
    MatIconModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(sanitizer: DomSanitizer, registry: MatIconRegistry) {
    registry
      .addSvgIcon(
        'logo',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/logo.svg')
      )
      .addSvgIcon(
        'facebook',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/social-media/facebook.svg'
        )
      )
      .addSvgIcon(
        'instagram',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/social-media/instagram.svg'
        )
      )
      .addSvgIcon(
        'snapchat',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/social-media/snapchat.svg'
        )
      )
      .addSvgIcon(
        'twitter',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/social-media/twitter.svg'
        )
      )
      .addSvgIcon(
        'google-analytics',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/analytics/google-analytic.svg'
        )
      )
      .addSvgIcon(
        'google-tag-manager',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/analytics/google-tag-manager.svg'
        )
      )
      .addSvgIcon(
        'google-optimize',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/analytics/google-optimize.svg'
        )
      )
      .addSvgIcon(
        'cookie',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cookie.svg')
      );
  }
}
