import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize, iif } from 'rxjs';
import { AuthService } from '../auth.service';

@Component({
  selector: 'wzc-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  isLoading = false;

  form = this.fb.nonNullable.group({
    login: ['', [Validators.required]],
    password: ['', Validators.required],
    rememberMe: [true],
  });

  constructor(
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  signIn(): void {
    const { login, password, rememberMe } = this.form.getRawValue();
    this.isLoading = true;

    iif(
      () => rememberMe,
      this.auth.signInAndRemember(login, password),
      this.auth.signIn(login, password)
    )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => this.router.navigateByUrl('/'));
  }
}
